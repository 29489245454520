import { ErrorOutline } from '@iheartradio/web.accomplice/icons';
import { IllustratedMessage } from '@iheartradio/web.accomplice/illustrated-message';

import { useErrorContext } from '../hooks/error-context.js';
import type { CTAItemData } from '../types/cta-props.js';
import { ErrorCTA, ErrorCTAMap } from './app-error.utils.js';

interface CTAItemProps {
  cta: CTAItemData;
}

export function CTAItem(props: CTAItemProps) {
  const { cta } = props;

  const Component = ErrorCTAMap[cta.block];

  if (!Component) {
    console.warn(
      `CTA block ${cta.block} does not have an associated component`,
    );

    return null;
  }

  return <Component {...cta.props} />;
}

export const AppError = () => {
  const {
    errorSource: { title, description, cta: ctaItems },
  } = useErrorContext();

  return (
    <IllustratedMessage
      cta={
        <ErrorCTA>
          {ctaItems.map((cta, index) => {
            return <CTAItem cta={cta} key={`${index}-${cta.block}`} />;
          })}
        </ErrorCTA>
      }
      description={description}
      icon={ErrorOutline}
      title={title}
    />
  );
};
