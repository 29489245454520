import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Flex } from '@iheartradio/web.accomplice/flex';
import type { ReactNode } from 'react';

import { useErrorContext } from '../hooks/error-context.js';

export const AppErrorContainer = ({ children }: { children: ReactNode }) => {
  const { fromRoot } = useErrorContext();
  return (
    <Flex
      backgroundColor={lightDark(vars.color.gray100, vars.color.brandBlack)}
      data-test="page-error-container"
      flexGrow="1"
      height={fromRoot ? '100vh' : '100%'}
      justifyContent="center"
    >
      {children}
    </Flex>
  );
};
