import { Box } from '@iheartradio/web.accomplice/box';
import { Grid } from '@iheartradio/web.accomplice/grid';
import { LogotypeBeta } from '@iheartradio/web.accomplice/logos';
import { NavigationHeader } from '@iheartradio/web.accomplice/navigation';
import type { ReactNode } from 'react';

import { useErrorContext } from '../hooks/error-context.js';

export const AppErrorLayout = ({ children }: { children: ReactNode }) => {
  const { fromRoot } = useErrorContext();

  if (!fromRoot) {
    return <>{children}</>;
  }

  return (
    <Grid
      gridTemplateAreas={`"nav" "content"`}
      gridTemplateColumns="1fr"
      gridTemplateRows="4.8rem auto"
    >
      <Box data-test="grid area nav" gridArea="nav">
        <NavigationHeader style={{ display: 'flex' }}>
          <LogotypeBeta
            aria-label="iHeart Header Logotype"
            media="mobile"
            size={24}
          />
        </NavigationHeader>
      </Box>
      {children}
    </Grid>
  );
};
